@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;700&family=Merriweather:ital,wght@0,400;0,700;0,900;1,700;1,900&family=Roboto&family=Work+Sans&display=swap');

$accent : #ff9f89;
$accent-secondary : #ff7151;
$accent-secondary-active : #ff4116;
$accent-dark : #d12701;
$primary : white;
$secondary : #e8e8e8;
$font : 'Work Sans', sans-serif;
$font-bold : 'Merriweather', serif;
$page-w : 1200px;
$page-m : calc(50vw - ($page-w / 2)); 

@media only screen and (max-width: 900px) {
    $page-w : 100vw;
    $page-m : 0px;
}