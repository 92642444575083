@use 'theme' as t;

footer{
    background: rgb(21, 21, 21);
    padding: 50px t.$page-m;
    color: white;
}
.v-shivank{
    color: t.$accent;
    text-decoration: none;
}
.v-shivank:hover{
    text-decoration: underline;
}
.v-footer-wrapper{
    display: flex;
    .v-footer-logo{
        width: 100px;
        filter: brightness(0) invert(1);
        img{
            width: 100%;
        }
    }
}
.v-footer-contact{
    color: white;
    text-decoration: none;
    i{
        margin-right: 10px;
    }
    a{
        color: inherit;
        text-decoration: none;
    }
    padding-bottom: 20px;
    display: inline-flex;
}
a.v-footer-contact:hover{
    text-decoration: underline;
}
.v-footer-b{
    font-size: 14px;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgb(45, 45, 45);
}
.v-footer-details{
    margin-left: 50px;
}
@media only screen and (max-width: 900px) {
    .v-footer-wrapper{
        flex-direction: column;
        align-items: center;
        .v-footer-logo{
            width: 100px;
        }
    }
    .v-footer-details{
        margin: 0px;
        margin-top: 20px;
    }
    .v-footer-b{
        font-size: 14px;
        margin-top: 20px;
        padding: 20px;
        border-top: 1px solid rgb(45, 45, 45);
    }
    footer{
        padding-bottom: 0px;
    }
}