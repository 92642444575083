@use 'theme' as t;

header{
    --col : white;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    padding-left: t.$page-m;
    padding-right: t.$page-m;
    transition:0.2s;
    z-index: 10;
}
header.white{
    --col : black;
    background: t.$primary;
    color: black !important;
}
.v-header-title{
    display: flex;
    align-items: center;
    font-style: italic;
    text-decoration: none;
    color: var(--col);
}
.v-header-logo{
    padding: 20px;
    transition: 0.2s;
}
.v-header-logo img{
    height: 65px;
    transition: 0.2s;
}
.v-header-nav{
    text-decoration: none;
    margin: 10px 20px;
    color: inherit;
    transition: 0.2s;
    text-transform:capitalize;
}
.v-header-nav:hover{
    color: t.$accent;
}
.v-header-title-title{
    font-family: t.$font-bold;
    font-size: 32px;
}
.v-header-title-subtitle{
    overflow: hidden;
    transition: 0.2s;
    max-height: 500px;
}
header.white{
    border-bottom: 1px solid t.$secondary;
    .v-header-logo{
        padding: 10px;
    }
    .v-header-logo img{
        height: 40px;
    }
    .v-header-title-subtitle{
        max-height: 0px;
    }
    .v-header-title-title{
        font-size: 22px;
        font-weight: bold;
    }
    .v-header-nav:hover{
        color: t.$accent-secondary;
    }
}
.v-header-menu-btn{
    display: none;
    font-size: 30px;
    margin-right: 20px;
}
@media only screen and (max-width: 990px) {
    .v-header-nav{
        font-size: 13px;
    }
}
@media only screen and (max-width: 900px) {
    .v-header-nav-wrap{
        position: fixed;
        right: -100vw;
        top: 65px;
        bottom: 0px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        transition: 0.2s;
    }
    .v-header-nav-wrap.open{
        right: 0px;
    }
    .v-header-menu-btn{
        display: block;
    }
    .v-header-title-title{
        font-size: 20px;
    }
    .v-header-title-subtitle{
        max-height: 500px;
        font-size: 13px;
    }
    .v-header-logo img{
        height: 30px;
    }
    .v-header-logo{
        padding: 10px;
    }
    header{
        margin: 0px;
    }
    .v-header-nav{
        display: block;
        text-align: center;
        font-size: 30px;
        margin: 30px 0px;
        color: black;
        font-family: t.$font-bold;
        font-style: italic;
    }
}