@use 'theme';

body{
    background-color: theme.$primary;
    margin : 0px;
    padding: 0px;
    font-family: theme.$font;
}

iframe{
    border: 0px;
    height: 300px;
    width: 100%;
}

.v-fallback{
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heighter{
    padding-top: 65px; 
    margin-top: -65px;
}